import { t } from "@lingui/core/macro";
import { getPatientApiUrl } from "@/Utils/EnvUtils";
import type { IPostMessageData } from "@components/Iframe/Iframe";
import { Iframe } from "@components/Iframe/Iframe";
import { useNavigate } from "react-router";
import { prospectPatientSchema } from "@models/patients";
import { useCenterBaseUrl } from "@/Utils/useCenterBaseUrl";
import { useUserProfile } from "@/api/Users";

export const RegisterProspectPatient = () => {
  const navigate = useNavigate();
  const centerBaseUrl = useCenterBaseUrl();
  const { unit } = useUserProfile();

  const postMessageHandler = {
    messageType: "patientRegistered",
    onMessageReceived: (data: IPostMessageData) => {
      try {
        const { id: patientId } = prospectPatientSchema
          .pick({ id: true })
          .parse({ id: data.patientId });
        navigate(`${centerBaseUrl}/patients/${patientId}`);
      } catch (_error) {
        navigate(`${centerBaseUrl}/patients/prospect`);
      }
    },
  };

  return (
    <Iframe
      title={t`Registrera patient för utvärdering`}
      src={`${getPatientApiUrl(unit)}/htmx/center/patients/add`}
      loadingMessage={t`Laddar formulär`}
      loadingPadding={24}
      postMessageHandler={postMessageHandler}
    />
  );
};
