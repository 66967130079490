import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { type ITemplate } from "../templatesAlerisAhc";
import { newActivityFromTemplateAlerisAhc } from "../newActivityFromTemplateAlerisAhc";
import { templates } from "../templatesAlerisAhc";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { activityKeys, addActivitiesFromGroupTemplate } from "@/api/Activities";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesAlerisAhc.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { TemplateHeadingAlerisAhc } from "../TemplateHeadingAlerisAhc";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import HiddenIcon from "@components/icons/HiddenIcon";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { GroupTemplateHeadingAleris } from "../TemplateHeadingAlerisAhc";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { useLingui } from "@lingui/react";
import { medicalCompetenceSchema } from "@models/shifts";
import { patientTypeSchema } from "@models/patients";
import { newActivityFromTemplateDefault } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitDefault/newActivityFromTemplateDefault";
import { nextWednesday, isWednesday } from "date-fns";
import { format } from "@models/date-and-time";

/**
 * Admission Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID = "cbe6141a-7228-4d8f-8163-daccc65a3834";
const TEMPLATE_REVISION = 1;

const admissionActivities = {
  handoverMedicationList: templates.handoverMedicationList,
  keyQuittance: templates.keyQuittance,
  checkCatheter: templates.checkCatheter,
  riskAssessment: templates.riskAssessment,
  securityReceipt: templates.securityReceipt,
  admissionInterview: templates.admissionInterview,
  workEnvironmentRiskAssessment: templates.workEnvironmentRiskAssessment,
  teamRound: templates.teamRound,
  leaveInformationMap: templates.leaveInformationMap,
  updatePatientInformation: templates.updatePatientInformation,
  updateInTakeCare: templates.updateInTakeCare,
  documentRiskAssessment: templates.documentRiskAssessment,
  medicationReviewSimple: templates.medicationReviewSimple,
};

const admissionVisit: ITemplate = {
  title: msg`Inskrivningsbesök`,
  category: categorySchema.Values.HomeVisit,
  description: null,
  frequency: activityFrequencySchema.Values.oneTime,
  recurrence: recurringOptionSchema.Values.never,
  timeCategory: timeOfDaySchema.Values.Specific,
  timeslots: [{ time: "09:00" }],
  timeSensitivity: "4",
  requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
  doubleStaffing: false,
  duration: 15,
  hidden: false,
  measurements: [],
  weekdays: [],
  templateOptions: {
    showInList: false,
    patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
  },
  templateId: "11893e13-8208-450a-4095-1bc341be66c4",
  templateRevision: 1,
};

export const AdmissionTemplateAlerisAhc = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        handoverMedicationList: boolean;
        riskAssessment: boolean;
        keyQuittance: boolean;
        checkCatheter: boolean;
        admissionInterview: boolean;
        workEnvironmentRiskAssessment: boolean;
        teamRound: boolean;
        leaveInformationMap: boolean;
        updatePatientInformation: boolean;
        updateInTakeCare: boolean;
        medicationReviewSimple: boolean;
      }
  >({
    defaultValues: {
      recurrencesPerDay: "1",
      timeCategory: timeOfDaySchema.Values.Specific,
      timeslots: [{ time: "09:00" }],
      timeSensitivity: "4",
      handoverMedicationList: true,
      riskAssessment: true,
      admissionInterview: true,
      keyQuittance: true,
      checkCatheter: true,
      workEnvironmentRiskAssessment: true,
      teamRound: true,
      leaveInformationMap: true,
      updatePatientInformation: true,
      updateInTakeCare: true,
      medicationReviewSimple: true,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (activities: INewActivity[]) => {
      return addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: activityKeys.all,
      });
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      handoverMedicationList,
      riskAssessment,
      keyQuittance,
      checkCatheter,
      admissionInterview,
      leaveInformationMap,
      medicationReviewSimple,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.handoverMedicationList,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (riskAssessment) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.riskAssessment,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (admissionInterview) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.admissionInterview,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (leaveInformationMap) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.leaveInformationMap,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (medicationReviewSimple) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.medicationReviewSimple,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (keyQuittance) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.keyQuittance,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (checkCatheter) {
      activities.push(
        newActivityFromTemplateAlerisAhc({
          template: {
            ...admissionActivities.checkCatheter,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.workEnvironmentRiskAssessment,
          ...timeConfig,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.updatePatientInformation,
          ...timeConfig,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.updateInTakeCare,
          ...timeConfig,
        },
        startDate,
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateDefault({
        template: admissionActivities.teamRound,
        startDate: isWednesday(startDate)
          ? startDate
          : format(nextWednesday(startDate) as Date, "yyyy-MM-dd"),
        patientId,
      }),
    );

    activities.push(
      newActivityFromTemplateAlerisAhc({
        template: {
          ...admissionActivities.medicationReviewSimple,
          ...timeConfig,
        },
        startDate,
        patientId,
      }),
    );

    mutate(activities);
  });

  const { _ } = useLingui();

  return (
    <ExpansionPanel
      trigger={<GroupTemplateHeadingAleris title={t`Inskrivning`} />}
      triggerAriaLabel={t`Inskrivning`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingAlerisAhc
                template={{
                  ...admissionVisit,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: [
                    medicalCompetenceSchema.Values.NursePractitioner,
                  ],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  {/* Use fieldset to get boolean default values from checkboxes.
                  See https://react-hook-form.com/docs/useform/register */}
                  <fieldset disabled className={styles.fieldsetWithCheckboxes}>
                    <Checkbox
                      errorMessage={errors.admissionInterview?.message}
                      label={{
                        text: _(admissionActivities.admissionInterview.title),
                      }}
                      {...register("admissionInterview")}
                    />
                    <Checkbox
                      errorMessage={errors.handoverMedicationList?.message}
                      label={{
                        text: _(
                          admissionActivities.handoverMedicationList.title,
                        ),
                      }}
                      {...register("handoverMedicationList")}
                    />
                    <Checkbox
                      errorMessage={errors.medicationReviewSimple?.message}
                      label={{
                        text: _(
                          admissionActivities.medicationReviewSimple.title,
                        ),
                      }}
                      {...register("medicationReviewSimple")}
                    />
                    <div className={styles.checkboxRow}>
                      <Checkbox
                        errorMessage={errors.leaveInformationMap?.message}
                        label={{
                          text: _(
                            admissionActivities.leaveInformationMap.title,
                          ),
                        }}
                        {...register("leaveInformationMap")}
                      />
                      <HiddenIcon />
                    </div>
                    <div className={styles.checkboxRow}>
                      <Checkbox
                        errorMessage={errors.riskAssessment?.message}
                        label={{
                          text: _(admissionActivities.riskAssessment.title),
                        }}
                        {...register("riskAssessment")}
                      />
                      <HiddenIcon />
                    </div>
                  </fieldset>
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.keyQuittance?.message}
                      label={{
                        text: _(admissionActivities.keyQuittance.title),
                      }}
                      {...register("keyQuittance")}
                    />
                    <HiddenIcon />
                  </div>
                  <Checkbox
                    errorMessage={errors.checkCatheter?.message}
                    label={{ text: _(admissionActivities.checkCatheter.title) }}
                    {...register("checkCatheter")}
                  />
                </Checkboxes>
              </div>
            </div>
            <TemplateHeadingAlerisAhc
              template={admissionActivities.updateInTakeCare}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.updatePatientInformation}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.workEnvironmentRiskAssessment}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.documentRiskAssessment}
            />
            <TemplateHeadingAlerisAhc
              template={admissionActivities.teamRound}
            />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
