import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { type ITemplate } from "../templatesDefault";
import { templates } from "../templatesDefault";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { activityKeys, addActivitiesFromGroupTemplate } from "@/api/Activities";
import { addDays } from "date-fns";
import { newActivityFromTemplateDefault } from "../newActivityFromTemplateDefault";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesDefault.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { TemplateHeadingDefault } from "../TemplateHeadingDefault";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import HiddenIcon from "@components/icons/HiddenIcon";
import {
  categorySchema,
  measurementsDictionary,
  timeOfDaySchema,
} from "@models/activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { GroupTemplateHeadingDefault } from "../TemplateHeadingDefault";
import { format } from "@models/date-and-time";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { useLingui } from "@lingui/react";
import { medicalCompetenceSchema } from "@models/shifts";
import { allPatientTypes } from "@models/patients";

/**
 * Admission Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID = "76c86054-b415-44a2-bd85-61163a19571b";
const TEMPLATE_REVISION = 1;

const admissionActivities = {
  deliverMaterialBox: templates.deliverMaterialBox,
  handoverMedicationList: templates.handoverMedicationList,
  keyQuittance: templates.keyQuittance,
  deliverMedication: {
    ...templates.deliverMedication,
    recurrence: recurringOptionSchema.Values.never,
  },
  checkCatheter: templates.checkCatheter,
  riskAssessment: templates.riskAssessment,
  medicationWalkthrough: templates.medicationWalkthrough,
  replaceMaterialBox: templates.replaceMaterialBox,
  replaceAlarm: templates.replaceAlarm,
  round: templates.round,
  patientVitalsMorning: templates.patientVitalsMorning,
  fluidMeasurement: templates.fluidMeasurement,
  patientVitalsEvening: templates.patientVitalsEvening,
};

const admissionVisit: ITemplate = {
  title: msg`Inskrivningsbesök`,
  category: categorySchema.Values.HomeVisit,
  description: null,
  frequency: activityFrequencySchema.Values.oneTime,
  recurrence: recurringOptionSchema.Values.never,
  timeCategory: timeOfDaySchema.Values.Any,
  requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
  doubleStaffing: false,
  duration: 15,
  hidden: false,
  measurements: [],
  weekdays: [],
  templateOptions: {
    showInList: false,
    patientTypes: allPatientTypes,
  },
  templateId: "9e5592e1-49c0-47eb-b75e-118c352c123e",
  templateRevision: 1,
};

export const AdmissionTemplateDefault = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        deliverMaterialBox: boolean;
        handoverMedicationList: boolean;
        riskAssessment: boolean;
        keyQuittance: boolean;
        deliverMedication: boolean;
        checkCatheter: boolean;
        weight: boolean;
        fluids: boolean;
      }
  >({
    defaultValues: {
      recurrencesPerDay: "1",
      timeCategory: timeOfDaySchema.Values.Any,
      deliverMaterialBox: true,
      handoverMedicationList: true,
      riskAssessment: true,
      keyQuittance: true,
      deliverMedication: true,
      checkCatheter: true,
      weight: false,
      fluids: false,
    },
  });

  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    watch,
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (activities: INewActivity[]) => {
      return addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: activityKeys.all,
      });
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      deliverMaterialBox,
      handoverMedicationList,
      riskAssessment,
      keyQuittance,
      deliverMedication,
      checkCatheter,
      weight,
      fluids,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (deliverMaterialBox) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.deliverMaterialBox,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.handoverMedicationList,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (riskAssessment) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.riskAssessment,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (keyQuittance) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.keyQuittance,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (deliverMedication) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.deliverMedication,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (checkCatheter) {
      activities.push(
        newActivityFromTemplateDefault({
          template: {
            ...admissionActivities.checkCatheter,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (fluids) {
      activities.push(
        newActivityFromTemplateDefault({
          template: admissionActivities.fluidMeasurement,
          startDate,
          patientId,
        }),
      );
    }

    activities.push(
      newActivityFromTemplateDefault({
        template: admissionActivities.replaceMaterialBox,
        startDate: format(addDays(new Date(startDate), 5), "yyyy-MM-dd"),
        patientId,
      }),
    );
    activities.push(
      newActivityFromTemplateDefault({
        template: admissionActivities.round,
        startDate: format(addDays(new Date(startDate), 1), "yyyy-MM-dd"),
        patientId,
      }),
    );
    activities.push(
      newActivityFromTemplateDefault({
        template: admissionActivities.medicationWalkthrough,
        startDate: format(addDays(new Date(startDate), 1), "yyyy-MM-dd"),
        patientId,
      }),
    );
    activities.push(
      newActivityFromTemplateDefault({
        template: weight
          ? {
              ...admissionActivities.patientVitalsMorning,
              measurements: [
                ...admissionActivities.patientVitalsMorning.measurements,
                "weight" as const,
              ],
            }
          : admissionActivities.patientVitalsMorning,
        startDate,
        patientId,
      }),
    );
    activities.push(
      newActivityFromTemplateDefault({
        template: admissionActivities.patientVitalsEvening,
        startDate,
        patientId,
      }),
    );

    mutate(activities);
  });

  const { _ } = useLingui();

  return (
    <ExpansionPanel
      trigger={<GroupTemplateHeadingDefault title={t`Inskrivning`} />}
      triggerAriaLabel={t`Inskrivning`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingDefault
                template={{
                  ...admissionVisit,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: watch("checkCatheter")
                    ? [medicalCompetenceSchema.Values.NurseAssistant]
                    : [medicalCompetenceSchema.Values.Support],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  {/* Use fieldset to get boolean default values from checkboxes.
                  See https://react-hook-form.com/docs/useform/register */}
                  <fieldset disabled className={styles.fieldsetWithCheckboxes}>
                    <Checkbox
                      errorMessage={errors.deliverMaterialBox?.message}
                      label={{
                        text: _(admissionActivities.deliverMaterialBox.title),
                      }}
                      {...register("deliverMaterialBox")}
                    />
                    <Checkbox
                      errorMessage={errors.handoverMedicationList?.message}
                      label={{
                        text: _(
                          admissionActivities.handoverMedicationList.title,
                        ),
                      }}
                      {...register("handoverMedicationList")}
                    />
                    <div className={styles.checkboxRow}>
                      <Checkbox
                        errorMessage={errors.riskAssessment?.message}
                        label={{
                          text: _(admissionActivities.riskAssessment.title),
                        }}
                        {...register("riskAssessment")}
                      />
                      <HiddenIcon />
                    </div>
                  </fieldset>
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      errorMessage={errors.keyQuittance?.message}
                      label={{
                        text: _(admissionActivities.keyQuittance.title),
                      }}
                      {...register("keyQuittance")}
                    />
                    <HiddenIcon />
                  </div>
                  <Checkbox
                    errorMessage={errors.deliverMedication?.message}
                    label={{
                      text: _(admissionActivities.deliverMedication.title),
                    }}
                    {...register("deliverMedication")}
                  />
                  <Checkbox
                    errorMessage={errors.checkCatheter?.message}
                    label={{ text: _(admissionActivities.checkCatheter.title) }}
                    {...register("checkCatheter")}
                  />
                </Checkboxes>
              </div>
            </div>
            <TemplateHeadingDefault
              template={admissionActivities.replaceMaterialBox}
            />
            <TemplateHeadingDefault template={admissionActivities.round} />
            <TemplateHeadingDefault
              template={admissionActivities.medicationWalkthrough}
            />
            <div>
              <TemplateHeadingDefault
                template={admissionActivities.patientVitalsMorning}
              />
              <div className={styles.checkboxes}>
                <Checkboxes>
                  <Checkbox
                    errorMessage={errors.weight?.message}
                    label={{ text: _(measurementsDictionary.weight) }}
                    {...register("weight")}
                  />
                  <Checkbox
                    errorMessage={errors.fluids?.message}
                    label={{
                      text: _(admissionActivities.fluidMeasurement.title),
                    }}
                    {...register("fluids")}
                  />
                </Checkboxes>
              </div>
            </div>
            <TemplateHeadingDefault
              template={admissionActivities.patientVitalsEvening}
            />
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
