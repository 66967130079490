import { Trans } from "@lingui/react/macro";
import { ActivitiesTable } from "./ActivitiesTable";
import { PlainButton } from "@components/Button/Button";
import PlusIcon from "@components/icons/PlusIcon";
import { PageToolbar } from "@/components/PageToolbar/PageToolbar";
import { useNavigate } from "react-router";
import styles from "./index.module.scss";

export const Activities = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageToolbar showDaySwitcher>
        <div className={styles.actions}>
          <PlainButton onClick={() => navigate(`new`)}>
            <PlusIcon />
            <Trans>Ny aktivitet</Trans>
          </PlainButton>
        </div>
      </PageToolbar>
      <ActivitiesTable />
    </>
  );
};
