import type { ICareHub } from "@models/patients";

// The haversine formula determines the great-circle distance between two points on a sphere given their longitudes and latitudes.
// Haversine formula: https://en.wikipedia.org/wiki/Haversine_formula
const calculateDistanceBetweenTwoPoints = (
  lon1: number,
  lat1: number,
  lon2: number,
  lat2: number,
): number => {
  const radiusOfEarthInKm = 6378.137;
  const deltaLatitudeRadians = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  const deltaLongitudeRadians = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  const haversineFormulaComponent =
    Math.sin(deltaLatitudeRadians / 2) * Math.sin(deltaLatitudeRadians / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(deltaLongitudeRadians / 2) *
      Math.sin(deltaLongitudeRadians / 2);
  const angularDistanceInRadians =
    2 *
    Math.atan2(
      Math.sqrt(haversineFormulaComponent),
      Math.sqrt(1 - haversineFormulaComponent),
    );
  const distanceInMeters = radiusOfEarthInKm * angularDistanceInRadians * 1000;
  return distanceInMeters;
};

export const getValidCoordinates = ({
  longitude,
  latitude,
  careHub,
  maxDistance,
}: {
  longitude: number;
  latitude: number;
  careHub: ICareHub;
  maxDistance: number;
}) => {
  if (!careHub) {
    return null;
  }

  if (isNaN(longitude) || isNaN(latitude)) return null;

  const distance = calculateDistanceBetweenTwoPoints(
    longitude,
    latitude,
    careHub.longitude,
    careHub.latitude,
  );

  if (distance < maxDistance) {
    return { longitude, latitude };
  }

  return null;
};
