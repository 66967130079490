import { useEffect, useRef, useState } from "react";

const useResizablePanels = ({
  initialLeftPanelWidth = "50%",
  minPanelWidth = 450,
}: {
  initialLeftPanelWidth?: string | number;
  minPanelWidth?: number;
} = {}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);
  const isResizingRef = useRef(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState<string | number>(
    initialLeftPanelWidth,
  );

  useEffect(() => {
    const container = containerRef.current;
    const leftPanel = leftPanelRef.current;
    const handle = handleRef.current;

    if (!container || !leftPanel || !handle) return;

    const startResizing = (event: MouseEvent) => {
      event.preventDefault(); // Prevents text selection
      isResizingRef.current = true;
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", stopResizing);
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (!isResizingRef.current || !container) return;

      const containerRect = container.getBoundingClientRect();
      let newWidth = event.clientX - containerRect.left;
      newWidth = Math.max(
        minPanelWidth,
        Math.min(containerRect.width - minPanelWidth, newWidth),
      );

      setLeftPanelWidth(newWidth);
    };

    const stopResizing = () => {
      isResizingRef.current = false;
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", stopResizing);
    };

    handle.addEventListener("mousedown", startResizing);

    return () => {
      handle.removeEventListener("mousedown", startResizing);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", stopResizing);
    };
  }, [minPanelWidth]);

  useEffect(() => {
    if (leftPanelRef.current) {
      leftPanelRef.current.style.width =
        typeof leftPanelWidth === "string"
          ? leftPanelWidth
          : `${leftPanelWidth}px`;
    }
  }, [leftPanelWidth]);

  return { containerRef, leftPanelRef, handleRef, leftPanelWidth };
};

export default useResizablePanels;
