import { msg } from "@lingui/core/macro";
import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import type { MessageDescriptor } from "@lingui/core";
import {
  allPatientTypes,
  type IPatientType,
  patientTypeSchema,
} from "@models/patients";

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "frequency"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
    patientTypes: IPatientType[];
  };
  templateId: string;
  templateRevision: number;
  title: MessageDescriptor;
  description: MessageDescriptor | null;
};

type IAnyTimeOfDayTemplate = Omit<
  IBaseTemplate,
  "timeslots" | "timeSensitivity" | "recurrencesPerDay"
>;

// Must be a subset of IAddActivityFormData
export type ITemplate = IBaseTemplate | IAnyTimeOfDayTemplate;

export type ITemplateKey =
  | "returnKey"
  | "keyQuittance"
  | "checkCatheter"
  | "riskAssessment"
  | "patientVitals"
  | "ownMeasurementBloodSugar"
  | "handoverMedicationList"
  | "bloodSample"
  | "medication"
  | "workEnvironmentRiskAssessment";

export const templates: Record<ITemplateKey, ITemplate> = {
  patientVitals: {
    title: msg`Egen mätning`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "5c378477-6ad8-427c-4ce2-a4330326dbb7",
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: msg`Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "08a98552-a695-418c-0b72-4a16eebd8971",
    templateRevision: 1,
  },
  checkCatheter: {
    title: msg`Kontroll medicinska accesser och dränage`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Centrala infarter, kateter, stomi, såromläggning, drän, nefrostomi`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "018f0e9f-8a9b-4def-1fa8-b4e68c4abf4e",
    templateRevision: 1,
  },
  keyQuittance: {
    title: msg`Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "713cd2e5-8986-4d3f-be3f-05f1f148a4f6",
    templateRevision: 1,
  },
  riskAssessment: {
    title: msg`Riskbedömningar`,
    category: categorySchema.Values.HomeVisit,
    description: msg`ROAG, MNA, Downton och Norton`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "d00182c9-2f23-4c81-2d31-d73f60d3f2c6",
    templateRevision: 1,
  },
  bloodSample: {
    title: msg`Provtagning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: recurringOptionSchema.Values.never,
    frequency: activityFrequencySchema.Values.oneTime,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeSensitivity: "4",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "adcaa5ea-be14-412d-15dd-295d9c9bc0d3",
    templateRevision: 1,
  },
  returnKey: {
    title: msg`Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "31fd878f-de34-490b-1418-6e087a7f61ed",
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: msg`Lämna aktuell läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "fd2e4b2e-f4f4-479c-c758-6c784870cf13",
    templateRevision: 1,
  },
  medication: {
    title: msg`Läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  workEnvironmentRiskAssessment: {
    title: msg`Arbetsmiljö riskbedömning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
};
