import { ActivityTemplatesAlerisAhc } from "./UnitAlerisAhc/ActivityTemplatesAlerisAhc";
import { ActivityTemplatesDefault } from "./UnitDefault/ActivityTemplatesDefault";
import { ActivityTemplatesOgNvk } from "./UnitOgNvk/ActivityTemplatesOgNvk";
import { ActivityTemplatesSivNsvt } from "./UnitSivNsvt/ActivityTemplatesSivNsvt";
import { useUserProfile } from "@/api/Users";

export const ActivityTemplates = () => {
  const { unit } = useUserProfile();

  switch (unit) {
    case "alerisahc":
      return <ActivityTemplatesAlerisAhc />;
    case "ognvk":
      return <ActivityTemplatesOgNvk />;
    case "sivnsvt":
      return <ActivityTemplatesSivNsvt />;
    default:
      return <ActivityTemplatesDefault />;
  }
};
