import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import { useParams } from "react-router";
import {
  informationFieldConfigValueSchema,
  patientKeys,
  removeRelative,
  usePatient,
  usePatientInformationFields,
  usePatientTypes,
} from "@/api/Patients";
import AddressComponent from "@/components/Address/Address";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import styles from "./Information.module.scss";
import type { IPlainButton } from "@components/Button/Button";
import { PlainButton } from "@components/Button/Button";
import { useState } from "react";
import { EditPhoneNumber } from "@/forms/EditPhoneNumber";
import { EditAddress } from "@/forms/EditAddress";
import { EditInformation } from "@/forms/EditInformation";
import { AddRelative } from "@/forms/AddRelative";
import DropdownMenu from "@/components/DropdownMenu/DropdownMenu";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deducedError, displayErrorMessageAlert } from "@/Utils/ErrorUtils";
import { EditHealthcareJourneyNumber } from "@/forms/EditHealthcareJourneyNumber";
import { EditSafetyAlarmText } from "@/forms/EditSafetyAlarm";
import PlusIcon from "@components/icons/PlusIcon";
import { z } from "zod";
import { Heading } from "@components/Heading/Heading";
import { EditName } from "@/forms/EditName";
import { dateName } from "@/Utils/DateUtils";
import { EditWard } from "@/forms/EditWard";
import { knownFeatureFlagsSchema, useFeatureFlag } from "@/api/FeatureFlags";
import * as Sentry from "@sentry/react";
import { useLingui } from "@lingui/react";
import { patientTypeDictionary } from "@models/patients";
import { EditArea } from "@/forms/EditArea";
import { EditPets } from "@/forms/EditPets";
import { EditHomeCare } from "@/forms/EditHomeCare";
import { EditLifeCare } from "@/forms/EditLifeCare";
import { TextWithLineBreaks } from "@components/Text/Text";

const Information = () => {
  const { _ } = useLingui();

  const { data: patientTypes } = usePatientTypes();
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const [currentlyEditing, setCurrentlyEditing] = useState<
    | "address"
    | "phonenumber"
    | "healthcareJourneyNumber"
    | "information"
    | "add-relative"
    | "safety-alarm"
    | "name"
    | "ward"
    | "area"
    | "pets"
    | "homeCare"
    | "lifeCare"
    | null
  >(null);
  const { data: patient, isPending, isError, error } = usePatient(patientId);
  const { data: cardiologyEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.CardiologyWorkflow,
  );
  const { data: areaEnabled } = useFeatureFlag(
    knownFeatureFlagsSchema.Values.Area,
  );

  const {
    data: conditionalInformationFields,
    isPending: isPendingConditionalInformationFields,
    isError: isErrorConditionalInformationFields,
    error: errorConditionalInformationFields,
  } = usePatientInformationFields();

  const queryClient = useQueryClient();
  const { mutate: removeRelativeMutation, isPending: isRemovingRelative } =
    useMutation({
      mutationFn: ({
        relativeId,
        patientId,
      }: {
        relativeId: string;
        patientId: string;
      }) => removeRelative(patientId, relativeId),
      onError: (error) => {
        displayErrorMessageAlert(
          `${t`Gick inte att ta bort anhörig.`} ${deducedError(error)}`,
        );
      },
      onSuccess: (_, { patientId }) => {
        queryClient.invalidateQueries({
          queryKey: patientKeys.detail(patientId),
        });
      },
    });

  if (isPending || isPendingConditionalInformationFields) {
    return <Loading message={_(msg`Laddar patientinformation`)} padding={24} />;
  }
  if (isError) {
    Sentry.captureException(error);
    return <ErrorMessage message={deducedError(error)} padding={24} />;
  }
  if (isErrorConditionalInformationFields) {
    Sentry.captureException(errorConditionalInformationFields);
    return (
      <ErrorMessage
        message={deducedError(errorConditionalInformationFields)}
        padding={24}
      />
    );
  }

  const EditButton = ({
    children,
    onClick,
  }: Pick<IPlainButton, "children" | "onClick">) => (
    <PlainButton size="small" weight="light" onClick={onClick}>
      {children}
    </PlainButton>
  );

  const DateOf = () => {
    const { status } = patient;
    const [heading, content] =
      status === "prospect"
        ? [t`Registreringsdatum`, dateName(patient.registeredAt)]
        : status === "admitted"
          ? [t`Inskrivningsdatum`, dateName(patient.admittedAt)]
          : status === "discharged"
            ? [t`Utskrivningsdatum`, dateName(patient.dischargedAt)]
            : [t`[Rubrik saknas]`, "-"];

    return (
      <>
        <section className={styles.informationSection}>
          <Heading level="h2">{heading}</Heading>
          {content}
        </section>
        {patientTypes && patientTypes.length > 1 ? (
          <section className={styles.informationSection}>
            <Heading level="h2">
              <Trans>Patienttyp</Trans>
            </Heading>
            {_(patientTypeDictionary[patient.patientType])}
          </section>
        ) : null}
      </>
    );
  };

  const Name = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Namn</Trans>
          </Heading>
          {currentlyEditing !== "name" ? (
            <EditButton onClick={() => setCurrentlyEditing("name")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "name" ? (
          <EditName
            currentName={patient.name}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.name
        )}
      </section>
    );
  };

  const Address = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Adress</Trans>
          </Heading>
          {currentlyEditing !== "address" ? (
            <EditButton onClick={() => setCurrentlyEditing("address")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "address" ? (
          <EditAddress
            currentAddress={patient.address}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          <>
            <AddressComponent address={patient.address} showPostalCode />
            {patient.address.additionalInformation ? (
              <ul>
                {patient.address.additionalInformation
                  .split("\n")
                  .map((item, index) => (
                    <li key={item + index}>{item}</li>
                  ))}
              </ul>
            ) : undefined}
          </>
        )}
      </section>
    );
  };

  const Phone = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Telefonnummer</Trans>
          </Heading>
          {currentlyEditing !== "phonenumber" ? (
            <EditButton onClick={() => setCurrentlyEditing("phonenumber")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "phonenumber" ? (
          <EditPhoneNumber
            currentPhoneNumber={patient.phoneNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.phoneNumber
        )}
      </section>
    );
  };

  const GeneralInformation = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Allmän information</Trans>
          </Heading>
          {currentlyEditing !== "information" ? (
            <EditButton onClick={() => setCurrentlyEditing("information")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "information" ? (
          <EditInformation
            currentInformation={patient.information}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.information ? (
          <ul>
            {patient.information.split("\n").map((item, index) => (
              <li key={item + index}>{item}</li>
            ))}
          </ul>
        ) : (
          "-"
        )}
      </section>
    );
  };

  const Ward = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Avdelning</Trans>
          </Heading>
          {currentlyEditing !== "ward" ? (
            <EditButton onClick={() => setCurrentlyEditing("ward")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "ward" ? (
          <EditWard
            currentWard={patient.ward}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : (
          patient.ward.displayName
        )}
      </section>
    );
  };

  const GeographicalArea = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Område</Trans>
          </Heading>
          {currentlyEditing !== "area" ? (
            <EditButton onClick={() => setCurrentlyEditing("area")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "area" ? (
          <EditArea
            currentArea={patient.area}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.area ? (
          patient.area.displayName
        ) : (
          "-"
        )}
      </section>
    );
  };

  const Relatives = () => {
    return (
      <section className={styles.informationSection}>
        <Heading level="h2">
          <Trans>Anhöriga</Trans>
        </Heading>
        {patient.relatives.length >= 1 ? (
          <ul className={styles.relativeList}>
            {patient.relatives.map(
              ({
                name,
                phoneNumber,
                relation: relationUnknownCase,
                isLivingTogether,
              }) => {
                const relation = relationUnknownCase.toLocaleLowerCase();
                const patientName = patient.name;
                const optionalLivesTogetherWithSegment = isLivingTogether
                  ? t`bor med ${patientName} och`
                  : "";
                return (
                  <li key={name}>
                    <p>
                      <Trans>
                        {name} ({relation}) {optionalLivesTogetherWithSegment}{" "}
                        nås på {phoneNumber}
                      </Trans>
                    </p>
                  </li>
                );
              },
            )}
          </ul>
        ) : (
          "-"
        )}
        {currentlyEditing !== "add-relative" ? (
          <>
            <div className={styles.linedUpButtons}>
              <PlainButton onClick={() => setCurrentlyEditing("add-relative")}>
                <PlusIcon />
                <Trans>Lägg till anhörig</Trans>
              </PlainButton>
              {patient.relatives.length >= 1 ? (
                <DropdownMenu
                  trigger={{
                    text: t`Ta bort en anhörig`,
                    weight: "regular",
                  }}
                >
                  {patient.relatives.map((relative) => {
                    const relativeName = relative.name;
                    return (
                      <DropdownMenu.Item
                        action={() =>
                          removeRelativeMutation({
                            patientId: patient.id,
                            relativeId: relative.id,
                          })
                        }
                        content={t`Ta bort ${relativeName}`}
                        disabled={isRemovingRelative}
                        key={relative.id}
                      />
                    );
                  })}
                </DropdownMenu>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <EditButton onClick={() => setCurrentlyEditing(null)}>
            <Trans>Avbryt</Trans>
          </EditButton>
        )}
        {currentlyEditing === "add-relative" ? (
          <AddRelative
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : undefined}
      </section>
    );
  };

  const MedicalTransportNumber = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Sjukresenummer</Trans>
          </Heading>
          {currentlyEditing !== "healthcareJourneyNumber" ? (
            <EditButton
              onClick={() => setCurrentlyEditing("healthcareJourneyNumber")}
            >
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>

        {currentlyEditing === "healthcareJourneyNumber" ? (
          <EditHealthcareJourneyNumber
            currentHealthcareJourneyNumber={patient.healthcareJourneyNumber}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.healthcareJourneyNumber ? (
          <p>{patient.healthcareJourneyNumber}</p>
        ) : (
          "-"
        )}
      </section>
    );
  };

  const SafetyAlarm = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Trygghetslarm</Trans>
          </Heading>
          {currentlyEditing !== "safety-alarm" ? (
            <EditButton onClick={() => setCurrentlyEditing("safety-alarm")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "safety-alarm" ? (
          <EditSafetyAlarmText
            currentSafetyAlarmText={patient.safetyAlarmText}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.safetyAlarmText ? (
          <p>{patient.safetyAlarmText}</p>
        ) : (
          "-"
        )}
      </section>
    );
  };

  const Pets = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Husdjur</Trans>
          </Heading>
          {currentlyEditing !== "pets" ? (
            <EditButton onClick={() => setCurrentlyEditing("pets")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "pets" ? (
          <EditPets
            currentPets={patient.pet}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.pet ? (
          <TextWithLineBreaks>{patient.pet}</TextWithLineBreaks>
        ) : (
          "-"
        )}
      </section>
    );
  };

  const HomeCare = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>Hemtjänst</Trans>
          </Heading>
          {currentlyEditing !== "homeCare" ? (
            <EditButton onClick={() => setCurrentlyEditing("homeCare")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "homeCare" ? (
          <EditHomeCare
            currentHomeCare={patient.homeCare}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.homeCare ? (
          <p>
            <Trans>Ja</Trans>
          </p>
        ) : (
          <p>
            <Trans>Nej</Trans>
          </p>
        )}
      </section>
    );
  };

  const LifeCare = () => {
    return (
      <section className={styles.informationSection}>
        <div className={styles.editableSection}>
          <Heading level="h2">
            <Trans>LifeCare</Trans>
          </Heading>
          {currentlyEditing !== "lifeCare" ? (
            <EditButton onClick={() => setCurrentlyEditing("lifeCare")}>
              <Trans>Ändra</Trans>
            </EditButton>
          ) : (
            <EditButton onClick={() => setCurrentlyEditing(null)}>
              <Trans>Avbryt</Trans>
            </EditButton>
          )}
        </div>
        {currentlyEditing === "lifeCare" ? (
          <EditLifeCare
            currentLifeCare={patient.lifeCare}
            onSuccess={() => setCurrentlyEditing(null)}
            patientId={patient.id}
          />
        ) : patient.lifeCare ? (
          <p>
            <Trans>Ja</Trans>
          </p>
        ) : (
          <p>
            <Trans>Nej</Trans>
          </p>
        )}
      </section>
    );
  };

  return (
    <div className={styles.information}>
      <DateOf />
      <Name />
      <Address />
      <Phone />
      {conditionalInformationFields.GeneralInformation ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <GeneralInformation />
      )}
      {cardiologyEnabled ? <Ward /> : null}
      {areaEnabled ? <GeographicalArea /> : null}
      {conditionalInformationFields.CloseContact ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <Relatives />
      )}
      {conditionalInformationFields.MedicalTransportNumber ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <MedicalTransportNumber />
      )}
      {conditionalInformationFields.PersWristband ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <SafetyAlarm />
      )}
      {conditionalInformationFields.Pet ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <Pets />
      )}
      {conditionalInformationFields.HomeCare ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <HomeCare />
      )}
      {conditionalInformationFields.LifeCare ===
      informationFieldConfigValueSchema.Values.hidden ? null : (
        <LifeCare />
      )}
    </div>
  );
};

export default Information;
