import { t } from "@lingui/core/macro";
import { BlockChip } from "@/components/Chips/BlockChip";
import {
  useRoutes,
  getRouteFromActivityOccurrence,
  getRouteFromGroup,
} from "@/api/Routes";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import * as Sentry from "@sentry/react";
import { useSelectedDate } from "@/Utils/useSelectedDate";

type IBlock =
  | {
      activityId: string;
      activityOccurrenceId: string;
    }
  | {
      groupId: string;
    };

export const BlockCell = (props: IBlock) => {
  const selectedDate = useSelectedDate();

  const {
    data: routes,
    isPending,
    isError,
    error,
  } = useRoutes({ date: selectedDate });

  if (isPending) {
    return <BlockChip label={t`Laddar...`} />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte hämta rutterna.`} ${deducedError(error)}`}
      />
    );
  }

  const assignedBlock =
    "groupId" in props
      ? getRouteFromGroup(routes, props.groupId)
      : getRouteFromActivityOccurrence(
          routes,
          props.activityId,
          props.activityOccurrenceId,
        );

  const isAssignedToBlock = assignedBlock !== undefined;

  if (!isAssignedToBlock) {
    return null;
  }

  return <BlockChip label={assignedBlock.name} />;
};
