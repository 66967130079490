import React from "react";
import clsx from "clsx";
import styles from "./Text.module.scss";

export type IText = React.HTMLAttributes<
  HTMLParagraphElement | HTMLSpanElement | HTMLDivElement
> & {
  color?: "default" | "faded";
  element: "p" | "span" | "div";
  size?: "base" | "small" | "large";
  weight?: "regular" | "medium" | "bold";
};

export const Text = ({
  children,
  color = "default",
  element,
  size = "base",
  weight = "regular",
  ...props
}: IText) => {
  if (children === undefined || children === null) {
    return <></>;
  }

  const htmlElement = element;

  const TextElement = ({
    ...props
  }: React.HTMLAttributes<
    HTMLParagraphElement | HTMLSpanElement | HTMLDivElement
  >) => React.createElement(htmlElement, props, children);

  return (
    <TextElement
      {...props}
      className={clsx(
        styles[`color-${color}`],
        styles[`element-${element}`],
        styles[`size-${size}`],
        styles[`weight-${weight}`],
        props.className,
      )}
    >
      {children}
    </TextElement>
  );
};

export type ITextWithLineBreaks = Omit<IText, "element"> & {
  children: string | null | undefined;
};

export const TextWithLineBreaks = ({
  children,
  className,
  color = "default",
  size = "base",
  weight = "regular",
  ...props
}: ITextWithLineBreaks) => {
  if (children === undefined || children === null) {
    return <></>;
  }

  const textAsArray = children.split("\n");

  if (textAsArray.length === 1) {
    return (
      <Text element="p" color={color} size={size} weight={weight} {...props}>
        {children}
      </Text>
    );
  }

  return (
    <ul
      {...props}
      className={clsx(
        styles[`color-${color}`],
        styles[`element-ul`],
        styles[`size-${size}`],
        styles[`weight-${weight}`],
        className,
      )}
    >
      {textAsArray.map((item, index) => (
        <li key={item + index}>
          <Text element="p" color={color} size={size} weight={weight}>
            {item}
          </Text>
        </li>
      ))}
    </ul>
  );
};
