import { useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { notificationApi, patientApi } from "./ApiClient";
import type { IVideoService } from "@models/video";
import { generateQueryString } from "./Helpers";

const jitsiInfoSchema = z.object({ token: z.string(), domain: z.string() });
const acsInfoSchema = z.object({
  endpoint: z.string(),
  userId: z.string(),
  token: z.string(),
  groupId: z.string(),
});

const videoKeys = {
  all: ["video"] as const,
  info: (patientId: string, videoService: IVideoService) =>
    [...videoKeys.all, "info", { patientId, videoService }] as const,
};

async function getVideoInfo(patientId: string, videoService: IVideoService) {
  const path = `patients/${patientId}/videocall`;
  if (videoService === "Jitsi") {
    const queryParameters = generateQueryString({
      "video-service": "jitsi",
    });
    const url = `${path}${queryParameters}`;

    const response = await patientApi.post(url);
    return {
      ...jitsiInfoSchema.parse(response.data),
      videoService: "Jitsi" as const,
    };
  }
  if (videoService === "ACS") {
    const queryParameters = generateQueryString({
      "video-service": "acs",
    });
    const url = `${path}${queryParameters}`;

    const response = await patientApi.post(url);
    return {
      ...acsInfoSchema.parse(response.data),
      videoService: "ACS" as const,
    };
  }

  return null;
}

export const useVideoInfo = (
  patientId: string,
  videoService: IVideoService,
) => {
  return useQuery({
    queryKey: videoKeys.info(patientId, videoService),
    queryFn: () => getVideoInfo(patientId, videoService),
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};

export const callPatient = async (
  patientId: string,
  videoService: "jitsi" | "acs",
) => {
  const url = `/videocall`;
  await notificationApi.post(url, {
    userId: patientId,
    videoService,
  });
};
