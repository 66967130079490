import { Trans } from "@lingui/react/macro";
import { msg, t } from "@lingui/core/macro";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { type ITemplate } from "../templatesOgNvk";
import { templates } from "../templatesOgNvk";
import type { ITimeSlotsPickerFields } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { TimeSlotsPicker } from "@/forms/AddActivityForm/Scheduling/TimeSlotsPicker";
import { useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import z from "zod";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import type { INewActivity } from "@/api/Activities";
import { activityKeys, addActivitiesFromGroupTemplate } from "@/api/Activities";
import { newActivityFromTemplateOgNvk } from "../newActivityFromTemplateOgNvk";
import { ExpansionPanel } from "@components/ExpansionPanel/ExpansionPanel";
import styles from "../ActivityTemplatesOgNvk.module.scss";
import Form from "@/components/Form/Form";
import { FilledButton } from "@components/Button/Button";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import {
  TemplateHeadingOgNvk,
  GroupTemplateHeadingAleris,
} from "../TemplateHeadingOgNvk";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import type { IDateInputFields } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { DateInput } from "@/forms/AddActivityForm/Scheduling/DateInput";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import { useLingui } from "@lingui/react";
import { allPatientTypes } from "@models/patients";

/**
 * Discharge Template Id and revision number
 * NEVER change the Id.
 * Increment revision number when you make changes to the template.
 */
const TEMPLATE_ID = "be55b956-de92-4cb0-b1ad-db6cc89843fa";
const TEMPLATE_REVISION = 1;

const dischargeActivities = {
  retrieveMaterialBox: templates.retrieveMaterialBox,
  retrieveTechBox: templates.retrieveTechBox,
  handoverMedicationList: templates.handoverMedicationList,
  handoverTreatmentMessage: templates.handoverTreatmentMessage,
  patientSurvey: templates.patientSurvey,
  returnKey: templates.returnKey,
};

const dischargeVisit: ITemplate = {
  title: msg`Utskrivningsbesök`,
  category: categorySchema.Values.HomeVisit,
  description: null,
  frequency: activityFrequencySchema.Values.oneTime,
  recurrence: recurringOptionSchema.Values.never,
  timeCategory: timeOfDaySchema.Values.Any,
  requiredCompetences: [medicalCompetenceSchema.Values.NurseAssistant],
  doubleStaffing: false,
  duration: 15,
  hidden: false,
  measurements: [],
  weekdays: [],
  templateOptions: {
    showInList: false,
    patientTypes: allPatientTypes,
  },
  templateId: "3eb0cfcf-d688-4776-fffc-063adb9fc3a3",
  templateRevision: 1,
};

export const DischargeTemplateAleris = () => {
  const { _ } = useLingui();
  const [isExpanded, setIsExpanded] = useState(false);
  const { patientId } = z.object({ patientId: z.string() }).parse(useParams());
  const queryClient = useQueryClient();

  const methods = useForm<
    ITimeSlotsPickerFields &
      Pick<IDateInputFields, "startDate"> & {
        retrieveMaterialBox: boolean;
        retrieveTechBox: boolean;
        handoverMedicationList: boolean;
        handoverTreatmentMessage: boolean;
        patientSurvey: boolean;
        returnKey: boolean;
      }
  >({
    defaultValues: {
      recurrencesPerDay: "1",
      timeCategory: timeOfDaySchema.Values.Any,
      retrieveMaterialBox: true,
      retrieveTechBox: true,
      handoverMedicationList: true,
      handoverTreatmentMessage: true,
      patientSurvey: true,
      returnKey: true,
    },
  });

  const {
    register,
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: (activities: INewActivity[]) => {
      return addActivitiesFromGroupTemplate({
        templateId: TEMPLATE_ID,
        templateRevision: TEMPLATE_REVISION,
        newActivities: activities,
      });
    },
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: activityKeys.all,
      });
      setIsExpanded(false);
    },
  });

  const validateAndSubmit = handleSubmit((formData) => {
    const {
      startDate,
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
      retrieveMaterialBox,
      retrieveTechBox,
      handoverMedicationList,
      handoverTreatmentMessage,
      patientSurvey,
      returnKey,
    } = formData;

    const timeConfig = {
      recurrencesPerDay,
      timeCategory,
      timeslots,
      timeSensitivity,
    };

    const activities: INewActivity[] = [];

    if (retrieveMaterialBox) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.retrieveMaterialBox,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (retrieveTechBox) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.retrieveTechBox,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (handoverMedicationList) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.handoverMedicationList,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (handoverTreatmentMessage) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.handoverTreatmentMessage,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (patientSurvey) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.patientSurvey,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }
    if (returnKey) {
      activities.push(
        newActivityFromTemplateOgNvk({
          template: {
            ...dischargeActivities.returnKey,
            ...timeConfig,
          },
          startDate,
          patientId,
        }),
      );
    }

    mutate(activities);
  });

  return (
    <ExpansionPanel
      trigger={<GroupTemplateHeadingAleris title={t`Utskrivning`} />}
      triggerAriaLabel={t`Utskrivning`}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    >
      <div className={styles.content}>
        <FormProvider {...methods}>
          <Form onSubmit={validateAndSubmit}>
            <div>
              <TemplateHeadingOgNvk
                template={{
                  ...dischargeVisit,
                  // TODO: Build a dynamic way to get required competences from the included activities.
                  // This will be needed for future batches of activities.
                  requiredCompetences: [medicalCompetenceSchema.Values.Support],
                }}
              />
              <div className={styles.dayAndTime}>
                <DateInput hasEndDate={false} />
                <TimeSlotsPicker isRecurring={false} />
              </div>
              <div className={styles.checkboxes}>
                <Checkboxes>
                  {/* Use fieldset to get boolean default values from checkboxes.
                  See https://react-hook-form.com/docs/useform/register */}
                  <fieldset disabled className={styles.fieldsetWithCheckboxes}>
                    <Checkbox
                      errorMessage={errors.retrieveMaterialBox?.message}
                      label={{
                        text: _(dischargeActivities.retrieveMaterialBox.title),
                      }}
                      {...register("retrieveMaterialBox")}
                    />
                    <Checkbox
                      errorMessage={errors.retrieveTechBox?.message}
                      label={{
                        text: _(dischargeActivities.retrieveTechBox.title),
                      }}
                      {...register("retrieveTechBox")}
                    />
                    <Checkbox
                      errorMessage={errors.handoverMedicationList?.message}
                      label={{
                        text: _(
                          dischargeActivities.handoverMedicationList.title,
                        ),
                      }}
                      {...register("handoverMedicationList")}
                    />
                    <Checkbox
                      errorMessage={errors.handoverTreatmentMessage?.message}
                      label={{
                        text: _(
                          dischargeActivities.handoverTreatmentMessage.title,
                        ),
                      }}
                      {...register("handoverTreatmentMessage")}
                    />
                    <Checkbox
                      errorMessage={errors.patientSurvey?.message}
                      label={{
                        text: _(dischargeActivities.patientSurvey.title),
                      }}
                      {...register("patientSurvey")}
                    />
                  </fieldset>
                  <Checkbox
                    errorMessage={errors.returnKey?.message}
                    label={{ text: _(dischargeActivities.returnKey.title) }}
                    {...register("returnKey")}
                  />
                </Checkboxes>
              </div>
            </div>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage
                message={errors.root.server.message}
                weight="bold"
              />
            ) : null}
            <FilledButton type="submit" disabled={isPending}>
              <Trans>Lägg till aktiviteter</Trans>
            </FilledButton>
          </Form>
        </FormProvider>
      </div>
    </ExpansionPanel>
  );
};
