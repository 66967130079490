import z from "zod";
import { logisticsApi } from "@/api/ApiClient";
import { generateQueryString } from "@/api/Helpers";
import {
  patientTypesForInvoicingSchema,
  type IPatientType,
} from "@models/patients";
import { useQuery } from "@tanstack/react-query";
import { fetchPatient } from "@/api/Patients";
import { fetchActivity } from "@/api/Activities";
import { categorySchema, type IActivityCategory } from "@models/activities";

export const invoicingCodeType = z.enum(["procedureCode", "productCode"]);

export type IInvoicingCodeType = z.infer<typeof invoicingCodeType>;

export const invoicingCodeSchema = z.object({
  id: z.string(),
  name: z.string(),
  code: z.string(),
  type: invoicingCodeType,
  patientType: patientTypesForInvoicingSchema,
});

export type IInvoicingCode = z.infer<typeof invoicingCodeSchema>;

const activityInvoicingCodesSchema = z
  .object({
    procedureCodes: z.array(invoicingCodeSchema),
    productCodes: z.array(invoicingCodeSchema),
  })
  .nullish();

export type IActivityInvoicingCodes = z.infer<
  typeof activityInvoicingCodesSchema
>;

export async function fetchInvoicingCodes({
  patientType,
  codeType,
}: {
  patientType: IPatientType;
  codeType: IInvoicingCodeType | null;
}) {
  const queryString = generateQueryString({ codeType, patientType });
  const response = await logisticsApi.get(
    `/invoicing/invoicing-codes${queryString}`,
  );

  return z.array(invoicingCodeSchema).parse(response.data);
}

export const useInvoicingCodes = ({
  patientId,
  codeType,
}: {
  patientId: string | undefined;
  codeType: IInvoicingCodeType | null;
}) => {
  return useQuery({
    queryKey: ["invoicingCodes", patientId, codeType],
    queryFn: async () => {
      if (!patientId) {
        return [];
      }
      const patient = await fetchPatient(patientId);
      return fetchInvoicingCodes({
        patientType: patient.patientType,
        codeType,
      });
    },
  });
};

//TODO: Look over this function if it can be done with less calls
export const useInvoicingCodesFromActivityId = ({
  activityId,
  codeType,
}: {
  activityId: string;
  codeType: IInvoicingCodeType | null;
}) => {
  return useQuery({
    queryKey: ["invoicingCodes", activityId, codeType],
    queryFn: async () => {
      const activity = await fetchActivity(activityId);
      if (!activity.patientId) {
        return [];
      }
      const patient = await fetchPatient(activity.patientId);
      return fetchInvoicingCodes({
        patientType: patient.patientType,
        codeType,
      });
    },
  });
};

export const saveActivityInvoicingCodes = async ({
  activityId,
  patientId,
  procedureCodes,
  productCodes,
}: {
  activityId: string;
  patientId: string;
  procedureCodes: string[];
  productCodes: string[];
}) => {
  await logisticsApi.post(`/invoicing/activities/${activityId}`, {
    patientId,
    procedureCodes,
    productCodes,
  });
};

export const fetchActivityInvoicingCodes = async (activityId: string) => {
  const response = await logisticsApi.get(
    `/invoicing/activities/${activityId}/invoicing-codes`,
  );

  return activityInvoicingCodesSchema.parse(response.data);
};

export const useActivityInvoicingCodes = (activityId: string) => {
  return useQuery({
    queryKey: ["activityInvoicingCodes", activityId],
    queryFn: async () => fetchActivityInvoicingCodes(activityId),
  });
};

export const shouldSaveInvoice = ({
  invoicingActivated,
  category,
  procedureCodes,
  productCodes,
}: {
  invoicingActivated: boolean;
  category: IActivityCategory;
  procedureCodes: string[];
  productCodes: string[];
}) => {
  return (
    invoicingActivated &&
    (category === categorySchema.Values.HomeVisit ||
      category === categorySchema.Values.AdminTask) &&
    (procedureCodes.length > 0 || productCodes.length > 0)
  );
};
