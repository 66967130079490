import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { Loading } from "@components/Loading/Loading";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Navigate, useLocation } from "react-router";
import * as Sentry from "@sentry/react";
import { deducedError } from "@/Utils/ErrorUtils";
import { useCallback, useEffect } from "react";
import { scopes } from "@/Utils/EnvUtils";
import { useLingui } from "@lingui/react/macro";

export const Login = () => {
  const {
    activeNavigator,
    error,
    isAuthenticated,
    isLoading,
    signinRedirect,
    user,
  } = useAuth();

  const { state }: { state?: { from?: Location } } = useLocation();
  const { t } = useLingui();
  const redirectURL =
    // If there is a state in the user object, it means that the user was redirected to the login page, and this is where they came from. Respect that firstly.
    // If there is a from in the state object, it means that the user was redirected internally, and this is where they came from. Respect that secondly.
    // Default to root.
    user?.state
      ? user?.state
      : state?.from
        ? `${state?.from?.pathname}${state?.from?.search}`
        : "/";

  const signin = useCallback(() => {
    signinRedirect({
      acr_values: "idp:siths",
      scope: scopes,
      state: redirectURL,
    });
  }, [signinRedirect, redirectURL]);

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !isAuthenticated &&
      !activeNavigator &&
      !isLoading
    ) {
      signin();
    }
  }, [isAuthenticated, activeNavigator, isLoading, signin]);

  if (isAuthenticated) {
    return <Navigate to={redirectURL} replace />;
  }

  if (activeNavigator || isLoading) {
    return <Loading message={t`Loggar in`} padding={24} />;
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <ErrorMessage
        message={`${t`Kunde inte logga in.`} ${deducedError(error)}`}
        padding={24}
      />
    );
  }

  return null;
};
