import { useCallback, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { scopes } from "@/Utils/EnvUtils";
import { Navigate, useLocation } from "react-router";

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, events, signinRedirect } = useAuth();
  const location = useLocation();

  const signin = useCallback(() => {
    signinRedirect({
      acr_values: "idp:siths",
      scope: scopes,
      state: window.location.pathname,
    });
  }, [signinRedirect]);

  // Token can expire while the app is in background
  // Forcefully sign in again if this happens
  useEffect(() => {
    return events.addAccessTokenExpired(signin);
  }, [events, signin]);

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
