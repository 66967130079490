import { t } from "@lingui/core/macro";
import { getPatientApiUrl } from "@/Utils/EnvUtils";
import {
  patientsWithUnhandledMeasurementsQueryOptions,
  useParentRoutesPatient,
} from "@/api/Patients";
import { Iframe } from "@components/Iframe/Iframe";
import { useQueryClient } from "@tanstack/react-query";
import { useUserProfile } from "@/api/Users";

export const Measurements = () => {
  const queryClient = useQueryClient();
  const { unit } = useUserProfile();
  const patient = useParentRoutesPatient();
  const postMessageHandler = {
    messageType: "measurementAcknowledged",
    onMessageReceived: () => {
      queryClient.invalidateQueries({
        queryKey: patientsWithUnhandledMeasurementsQueryOptions.queryKey,
      });
    },
  };
  return (
    <Iframe
      title={t`Mätvärden`}
      src={`${getPatientApiUrl(unit)}/htmx/center/patients/${patient.id}/measurements?react=true`}
      loadingMessage={t`Hämtar mätvärden`}
      padding={24}
      postMessageHandler={postMessageHandler}
    />
  );
};
