import { Trans } from "@lingui/react/macro";
import { t } from "@lingui/core/macro";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import Form from "@/components/Form/Form";
import { activityKeys, addActivity, ANY_TIME_OF_DAY } from "@/api/Activities";
import type {
  INewActivity,
  INewHomeVisitActivity,
  INewPatientTaskActivity,
  INewVideoActivity,
  INewAdminTaskActivity,
  INewPatientMeasurementTaskActivity,
} from "@/api/Activities";
import type { ICompetencePickerFields } from "./CompetencePicker";
import { CompetencePicker } from "./CompetencePicker";
import { generateRandomUUID } from "@/Utils/UniqueId";
import Checkbox from "@/components/Checkbox/Checkbox";
import { deducedError } from "@/Utils/ErrorUtils";
import { FilledButton } from "@components/Button/Button";
import type {
  IVideoType,
  IActivityCategory,
  IMeasurementsType,
} from "@models/activities";
import {
  videoTypeSchema,
  measurementSchema,
  categorySchema,
  timeOfDaySchema,
} from "@models/activities";
import Checkboxes from "@/components/Checkbox/Checkboxes";
import { ActivityTypePicker } from "./ActivityTypePicker";
import { PatientSelect } from "./PatientSelect";
import type { ITitleInputFields } from "./TitleInput";
import { TitleInput } from "./TitleInput";
import { DescriptionInput } from "./DescriptionInput";
import type { ISchedulingFields } from "./Scheduling/Scheduling";
import { Scheduling } from "./Scheduling/Scheduling";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "./Scheduling/recurrenceUtils";
import { useEffect } from "react";
import { useSelectedDateNoDefault } from "@/Utils/useSelectedDate";
import { MeasurementsPicker } from "@/forms/AddActivityForm/MeasurementsPicker";
import { useTitleSuggestions } from "./titleSuggestions";
import { getTimeFields, getTimespan } from "./activityTimeUtils";
import { useLingui } from "@lingui/react";
import {
  type IInvoicingCodesPickerFields,
  InvoicingPicker,
} from "@/forms/AddActivityForm/InvoicingPicker";
import {
  saveActivityInvoicingCodes,
  shouldSaveInvoice,
  useInvoicingCodes,
} from "@/api/Invoicing";

export type IAddActivityFormData = ITitleInputFields &
  ISchedulingFields &
  ICompetencePickerFields &
  IInvoicingCodesPickerFields & {
    category: IActivityCategory;
    description: string;
    doubleStaffing: boolean;
    duration: number;
    hidden: boolean;
    patientId: string;
    type: IVideoType;
    measurements: IMeasurementsType[];
  };

export const AddActivityForm = () => {
  const { _ } = useLingui();
  const navigate = useNavigate();
  const selectedDate = useSelectedDateNoDefault();

  const { patientId: patientIdFromParams } = useParams();

  const titleSuggestions = useTitleSuggestions();

  const methods = useForm<IAddActivityFormData>({
    defaultValues: {
      hidden: false,
      patientId: "", // This ensures that the disabled "Välj patient" option is the default, rather than the first patient in the list.
      recurrence: recurringOptionSchema.Values.never,
      recurrencesPerDay: "1",
      startDate: selectedDate,
      timeCategory: timeOfDaySchema.Values.Specific,
      measurements: [
        measurementSchema.Values.bloodPressure,
        measurementSchema.Values.pulse,
        measurementSchema.Values.saturation,
        measurementSchema.Values.temperature,
      ],
      frequency: activityFrequencySchema.Values.oneTime,
    },
  });

  const {
    formState: { errors, isDirty },
    register,
    reset,
    resetField,
    getValues,
    handleSubmit,
    setError,
    watch,
  } = methods;

  const currentPatientId = watch(`patientId`);
  const patientId = patientIdFromParams ?? currentPatientId;

  const { data: availableInvoicingCodes } = useInvoicingCodes({
    patientId: patientId,
    codeType: null,
  });

  const invoicingActivated =
    availableInvoicingCodes && availableInvoicingCodes.length > 0;

  const queryClient = useQueryClient();
  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: ({
      newActivity,
      patientId,
      procedureCodes,
      productCodes,
    }: {
      newActivity: INewActivity;
      patientId: string;
      procedureCodes: string[];
      productCodes: string[];
    }) =>
      addActivity(newActivity).then(() =>
        shouldSaveInvoice({
          invoicingActivated: invoicingActivated ?? false,
          category: newActivity.category,
          procedureCodes: procedureCodes,
          productCodes: productCodes,
        })
          ? saveActivityInvoicingCodes({
              activityId: newActivity.activityId,
              patientId,
              procedureCodes,
              productCodes,
            })
          : null,
      ),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: activityKeys.all });
      navigate("..");
    },
  });

  const validateAndSubmit = handleSubmit((validatedFormData) => {
    const {
      patientId: patientIdFromForm,
      category,
      description,
      doubleStaffing,
      recurrence,
      requiredCompetences,
      startDate,
      endDate,
      timeCategory,
      timeSensitivity,
      timeslots,
      title,
      weekdays,
      hidden,
      measurements,
      duration,
      procedureCode,
      productCode,
    } = validatedFormData;

    const procedureCodes = procedureCode ? [procedureCode] : [];
    const productCodes = productCode ? [productCode] : [];

    const isAnyTimeOfDay = timeCategory === timeOfDaySchema.Values.Any;
    const isRecurring = recurrence !== recurringOptionSchema.Values.never;
    const isRecurringAsInterval =
      recurrence !== recurringOptionSchema.Values.custom &&
      recurrence !== recurringOptionSchema.Values.never;

    const commonActivityFields: Pick<
      INewActivity,
      | "activityId"
      | "category"
      | "description"
      | "duration"
      | "hidden"
      | "startDate"
      | "timespan"
      | "title"
    > = {
      activityId: generateRandomUUID(),
      category,
      description: description.trim(),
      duration: duration ?? 15,
      hidden,
      startDate,
      timespan: getTimespan({
        isAnyTimeOfDay,
        timeSensitivity,
      }),
      title: title.trim(),
    };

    const patientId = patientIdFromParams ?? patientIdFromForm;

    const timeFields = isRecurring
      ? {
          ...getTimeFields({
            isRecurringAsInterval,
            isAnyTimeOfDay,
            timeslots,
            weekdays,
            recurrence,
          }),
          endDate: endDate === "" ? undefined : endDate,
        }
      : {
          time: isAnyTimeOfDay
            ? ANY_TIME_OF_DAY
            : // @ts-expect-error we know that there is exactly one timeslot, but TS doesn't.
              timeslots[0].time,
        }; // We only have one timeslot if it's not recurring.

    if (category === categorySchema.Values.VideoCall) {
      const newActivity: INewVideoActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.VideoCall,
        requiredCompetences,
        ...timeFields,
        type: videoTypeSchema.Values.DigitalVisit,
      };

      mutate({ newActivity, patientId, procedureCodes, productCodes });
    }

    if (category === categorySchema.Values.HomeVisit) {
      const newActivity: INewHomeVisitActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.HomeVisit,
        requiredCompetences,
        ...timeFields,
        doubleStaffing,
      };

      mutate({ newActivity, patientId, procedureCodes, productCodes });
    }

    if (category === categorySchema.Values.PatientTask) {
      const newActivity: INewPatientTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.PatientTask,
        ...timeFields,
      };

      mutate({ newActivity, patientId, procedureCodes, productCodes });
    }

    if (category === categorySchema.Values.AdminTask) {
      const newActivity: INewAdminTaskActivity = {
        ...commonActivityFields,
        patientId: patientId ? patientId : null, // Represent lack of patient id as null.
        category: categorySchema.Values.AdminTask,
        requiredCompetences: requiredCompetences ? requiredCompetences : [],
        ...timeFields,
      };

      mutate({ newActivity, patientId, procedureCodes, productCodes });
    }

    if (category === categorySchema.Values.PatientMeasurementTask) {
      const newActivity: INewPatientMeasurementTaskActivity = {
        ...commonActivityFields,
        patientId,
        category: categorySchema.Values.PatientMeasurementTask,
        measurements: measurements,
        ...timeFields,
      };

      mutate({ newActivity, patientId, procedureCodes, productCodes });
    }
  });

  const currentCategory = watch(`category`);
  const activityHasPatient =
    currentPatientId !== "" || patientIdFromParams !== undefined;
  const currentCategoryAllowsCompetence =
    currentCategory === categorySchema.Values.HomeVisit ||
    currentCategory === categorySchema.Values.VideoCall ||
    currentCategory === categorySchema.Values.AdminTask;
  const currentCategoryRequiresCompetence =
    currentCategory === categorySchema.Values.HomeVisit ||
    currentCategory === categorySchema.Values.VideoCall;
  const currentCategoryHasInvoicingCode =
    (currentCategory === categorySchema.Values.HomeVisit ||
      currentCategory === categorySchema.Values.AdminTask) &&
    activityHasPatient;
  const currentCategoryRequiresPatient =
    currentCategory !== categorySchema.Values.AdminTask;
  const activityIsHideable =
    (currentCategory === categorySchema.Values.HomeVisit ||
      currentCategory === categorySchema.Values.VideoCall ||
      currentCategory === categorySchema.Values.PatientTask ||
      currentCategory === categorySchema.Values.PatientMeasurementTask) &&
    activityHasPatient;
  const currentCategoryMayRequireDoubleStaffing =
    currentCategory === categorySchema.Values.HomeVisit;
  const currentCategoryRequireMeasurements =
    currentCategory === categorySchema.Values.PatientMeasurementTask;

  useEffect(() => {
    if (!activityHasPatient) {
      resetField("hidden");
    }
  }, [activityHasPatient, resetField]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={validateAndSubmit}>
        {isAdding ? (
          <Loading message={t`Lägger till aktiviteten`} />
        ) : (
          <>
            {errors.root?.server?.message && !isDirty ? (
              <ErrorMessage message={errors.root.server.message} />
            ) : undefined}
            {/* ACTIVITY TYPE */}
            <Form.Section>
              <ActivityTypePicker
                categoryConditionals={{
                  currentCategoryRequiresCompetence,
                  currentCategoryMayRequireDoubleStaffing,
                }}
              />
            </Form.Section>
            {/* MEASUREMENTS */}
            {currentCategoryRequireMeasurements ? (
              <Form.Section>
                <MeasurementsPicker />
              </Form.Section>
            ) : null}
            {/* PATIENT, TITLE, DESCRIPTION */}
            <Form.Section width="half">
              {!patientIdFromParams ? (
                <PatientSelect required={currentCategoryRequiresPatient} />
              ) : (
                <></>
              )}
              <div>
                <TitleInput
                  suggestions={
                    currentCategory
                      ? titleSuggestions[currentCategory].map(
                          (untranslatedSuggestion) => _(untranslatedSuggestion),
                        )
                      : undefined
                  }
                />
              </div>
              <DescriptionInput />
            </Form.Section>
            {/* SCHEDULING */}
            <Form.Section>
              <Scheduling />
            </Form.Section>
            {/* COMPETENCE */}
            {currentCategoryAllowsCompetence ? (
              <Form.Section>
                <CompetencePicker
                  isRequired={currentCategoryRequiresCompetence}
                />
              </Form.Section>
            ) : null}

            {/* INVOICING */}
            {invoicingActivated && currentCategoryHasInvoicingCode ? (
              <Form.Section>
                <InvoicingPicker patientId={patientId} />
              </Form.Section>
            ) : null}

            {/* OTHER */}
            {currentCategoryMayRequireDoubleStaffing || activityIsHideable ? (
              <Form.Section>
                <Checkboxes legend={t`Övrigt`} orientation="horizontal">
                  {currentCategoryMayRequireDoubleStaffing ? (
                    <Checkbox
                      errorMessage={errors.doubleStaffing?.message}
                      label={{ text: t`Kräver dubbelbemanning` }}
                      visualStyle="framed"
                      {...register("doubleStaffing")}
                    />
                  ) : (
                    <></>
                  )}
                  {activityIsHideable ? (
                    <Checkbox
                      errorMessage={errors.hidden?.message}
                      label={{ text: t`Dölj aktiviteten för patienten` }}
                      visualStyle="framed"
                      {...register("hidden")}
                    />
                  ) : (
                    <></>
                  )}
                </Checkboxes>
              </Form.Section>
            ) : null}
            <Form.SubmitButtonWrapper>
              <FilledButton type="submit">
                <Trans>Skapa aktiviteten</Trans>
              </FilledButton>
            </Form.SubmitButtonWrapper>
          </>
        )}
      </Form>
    </FormProvider>
  );
};
