/* eslint-disable prefer-const */
// We have a pattern to use `let` in these files. Don't mess with it.

import { UserManager, type UserManagerSettings } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? () => `https://localhost:7240`
    : (unit: string) => `https://logistics-${unit}-dev.medoma.com`;
let devicesUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_DEVICES_BACKEND
    ? () => `https://localhost:7240`
    : (unit: string) => `https://devices-${unit}-dev.medoma.com`;
let mapboxApiUrl = `https://api.mapbox.com`;
let mapboxPublicAccessToken = `pk.eyJ1IjoibWVkb21hIiwiYSI6ImNsN2Z6c2J0bTAwOHozd25xM3JlNzZ6bjUifQ.v2XsEAYwGo00Vk0XZteAZQ`;
let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? () => `https://localhost:7023`
    : (unit: string) => `https://pas-${unit}-dev.medoma.com`;
let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? `https://localhost:5001`
    : `https://auth-dev.medoma.com`;
let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_NOTIFICATION_BACKEND
    ? () => `https://localhost:7240/notification`
    : (unit: string) => `https://logistics-${unit}-dev.medoma.com/notification`;
let environment = import.meta.env.DEV ? `local` : `dev`;

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === `app.medoma.com`) {
  logisticsApiUrl = (unit: string) => `https://logistics-${unit}.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}.medoma.com`;
  authApiUrl = `https://auth.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}.medoma.com/notification`;
  environment = `prod`;
  devicesUrl = (unit: string) => `https://devices-${unit}.medoma.com`;
} else if (hostname === `app.test.medoma.com`) {
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-test.medoma.com`;
  authApiUrl = `https://auth-test.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-test.medoma.com/notification`;
  environment = `test`;
  devicesUrl = (unit: string) => `https://devices-${unit}-test.medoma.com`;
} else if (hostname === `app.demo.medoma.com`) {
  logisticsApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com`;
  patientApiUrl = (unit: string) => `https://pas-${unit}-demo.medoma.com`;
  authApiUrl = `https://auth-demo.medoma.com`;
  notificationApiUrl = (unit: string) =>
    `https://logistics-${unit}-demo.medoma.com/notification`;
  environment = `demo`;
  devicesUrl = (unit: string) => `https://devices-${unit}-demo.medoma.com`;
}

export const scopes =
  "openid IdentityServerApi create.otp revoke_session set_temporary_pin organization logistic.api patient.api employee_profile";
export const oidcConfig: UserManagerSettings = {
  authority: authApiUrl,
  client_id: "medoma-center",
  redirect_uri: `${window.location.origin}/login`,
  scope: scopes,
};

export const userManager = new UserManager(oidcConfig);

export const {
  getLogisticsApiUrl,
  getPatientApiUrl,
  getNotificationApiUrl,
  getDevicesUrl,
  MAPBOX_API_URL,
  MAPBOX_PUBLIC_ACCESS_TOKEN,
  AUTH_API_URL,
  ENVIRONMENT,
  CHAT_ENVIRONMENT,
} = {
  getLogisticsApiUrl: (unit: string) => logisticsApiUrl(unit),
  getPatientApiUrl: (unit: string) => patientApiUrl(unit),
  getNotificationApiUrl: (unit: string) => notificationApiUrl(unit),
  getDevicesUrl: (unit: string) => devicesUrl(unit),
  MAPBOX_API_URL: mapboxApiUrl,
  MAPBOX_PUBLIC_ACCESS_TOKEN: mapboxPublicAccessToken,
  AUTH_API_URL: authApiUrl,
  ENVIRONMENT: environment,
  CHAT_ENVIRONMENT: environment === "local" ? "dev" : environment,
};
