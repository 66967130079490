import { t } from "@lingui/core/macro";
import { useAuth } from "react-oidc-context";
import { Navigate, useLocation } from "react-router";
import { getDevicesUrl } from "@/Utils/EnvUtils";
import { AppSelector } from "./login/AppSelector";
import MedicalCircleIcon from "@components/icons/MedicalCircleIcon";
import DevicesIcon from "@components/icons/DevicesIcon";
import { useUserProfile } from "@/api/Users";

const AuthenticatedIndex = () => {
  const { unit } = useUserProfile();

  return (
    <AppSelector
      routes={[
        {
          icon: <MedicalCircleIcon />,
          label: t`Center`,
          to: "/commandcenter",
        },
        {
          icon: <DevicesIcon />,
          label: t`Utrustning`,
          to: getDevicesUrl(unit),
        },
      ]}
    />
  );
};

const Index = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <AuthenticatedIndex />;
};

export default Index;
