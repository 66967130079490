import { msg } from "@lingui/core/macro";
import { Popover } from "@/components/Popover/Popover";
import DocumentWithTextIcon from "@components/icons/DocumentWithTextIcon";
import { useLingui } from "@lingui/react";
import { TextWithLineBreaks } from "@components/Text/Text";

export const ActivityDescriptionPopover = ({
  description,
}: {
  description: string;
}) => {
  const { _ } = useLingui();

  return (
    <Popover
      trigger={{
        icon: <DocumentWithTextIcon />,
        ariaLabel: _(msg`Visa beskrivning`),
      }}
    >
      <TextWithLineBreaks>{description}</TextWithLineBreaks>
    </Popover>
  );
};
