import { activityRequirementSchema } from "@/api/Activities";
import { deduplicatePrimitiveArray } from "@/Utils/arrayUtils";
import {
  categorySchema,
  type IAdminTaskOccurrence,
  type IHomeVisitActivityOccurrence,
  type IVideoActivityOccurrence,
} from "@models/activities";
import type { IScheduledShift } from "@models/shifts";

export const getUnfulfilledRequirements = ({
  assignees,
  occurrences,
}: {
  assignees: ReadonlyArray<Pick<IScheduledShift, "competence">>;
  occurrences: ReadonlyArray<
    | Pick<
        IHomeVisitActivityOccurrence,
        "category" | "requiredCompetences" | "doubleStaffing"
      >
    | Pick<IVideoActivityOccurrence, "category" | "requiredCompetences">
    | Pick<IAdminTaskOccurrence, "category" | "requiredCompetences">
  >;
}) => {
  const occurrencesRequirements = [
    ...deduplicatePrimitiveArray(
      occurrences.flatMap(({ requiredCompetences }) => requiredCompetences),
    ),
    ...(occurrences.some(
      (occurrence) =>
        occurrence.category === categorySchema.Values.HomeVisit &&
        occurrence.doubleStaffing,
    )
      ? [activityRequirementSchema.Values.DoubleStaffing]
      : []),
  ];

  const assignedShiftsCompetences = assignees.map(
    ({ competence }) => competence,
  );

  return occurrencesRequirements.filter((requirement) => {
    if (requirement === activityRequirementSchema.Values.NurseAssistant) {
      return !(
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.NurseAssistant,
        ) ||
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.NursePractitioner,
        )
      );
    }
    if (requirement === activityRequirementSchema.Values.Support) {
      return !(
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.Support,
        ) ||
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.NurseAssistant,
        ) ||
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.NursePractitioner,
        ) ||
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.MedicalDoctor,
        ) ||
        assignedShiftsCompetences.includes(
          activityRequirementSchema.Values.Physiotherapist,
        )
      );
    }
    if (requirement === activityRequirementSchema.Values.DoubleStaffing) {
      return assignees.length <= 1;
    }
    return !assignedShiftsCompetences.includes(requirement);
  });
};
