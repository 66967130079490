import { titleSuggestions as titleSuggestionsDefault } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitDefault/titleSuggestionsDefault";
import { titleSuggestions as titleSuggestionsAlerisAhc } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitAlerisAhc/titleSuggestionsAlerisAhc";
import { titleSuggestions as titleSuggestionsOgNvk } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitOgNvk/titleSuggestionsOgNvk";
import { titleSuggestions as titleSuggestionsSivNsvt } from "@/pages/commandcenter/Patients/Patient/Activities/AddActivity/Templates/UnitSivNsvt/titleSuggestionsSivNsvt";
import type { MessageDescriptor } from "@lingui/core";
import { useUserProfile } from "@/api/Users";

export const useTitleSuggestions = () => {
  const { unit } = useUserProfile();

  const unitTitleSuggestions = (() => {
    switch (unit) {
      case "alerisahc":
        return titleSuggestionsAlerisAhc;
      case "ognvk":
        return titleSuggestionsOgNvk;
      case "sivnsvt":
        return titleSuggestionsSivNsvt;
      default:
        return titleSuggestionsDefault;
    }
  })();

  type ITitleSuggestionCategoriesKey =
    | "HomeVisit"
    | "VideoCall"
    | "PatientTask"
    | "AdminTask"
    | "PatientMeasurementTask";

  const titleSuggestions: Record<
    ITitleSuggestionCategoriesKey,
    MessageDescriptor[]
  > = {
    HomeVisit: [...unitTitleSuggestions.homeVisitSuggestions],
    VideoCall: [...unitTitleSuggestions.videoCallSuggestions],
    PatientTask: [...unitTitleSuggestions.patientTaskSuggestions],
    AdminTask: [...unitTitleSuggestions.adminTaskSuggestions],
    PatientMeasurementTask: [
      ...unitTitleSuggestions.patientMeasurementTaskSuggestions,
    ],
  };
  return titleSuggestions;
};
