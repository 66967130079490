import { t } from "@lingui/core/macro";
import styles from "./Shifts.module.scss";
import { useEffect, useState } from "react";
import { useShiftsViewToolbar } from "./useShiftsViewToolbar";
import StandaloneCheckbox from "@/components/Checkbox/StandaloneCheckbox";
import { ShiftsSideBar } from "./SideBar/ShiftsSideBar";
import { ShiftsSection } from "./ShiftsSection";
import { ShiftsContextProvider } from "./ShiftsContextProvider";
import { MapProvider } from "react-map-gl";
import clsx from "clsx";
import { useUnplannedOccurrencesAndGroups } from "@/api/Activities";
import { useSelectedDate } from "@/Utils/useSelectedDate";
import { ShiftsMap } from "./ShiftsMap";
import { useMap } from "react-map-gl";
import useResizablePanels from "./useResizablePanels";
import { PageToolbar } from "@/components/PageToolbar/PageToolbar";

export type IAllocatableItemType = "group" | "activityOccurrence" | undefined;

export const Shifts = () => {
  return (
    <ShiftsContextProvider>
      <MapProvider>
        <InnerShifts />
      </MapProvider>
    </ShiftsContextProvider>
  );
};

const InnerShifts = () => {
  const [
    isExpanded,
    setIsExpanded,
    isMapShown,
    setIsMapShown,
    isToPlanShown,
    setIsToPlanShown,
  ] = useShiftsViewToolbar();
  const [isPendingAddToShift, setIsPendingAddToShift] = useState(false);

  const { planningMap } = useMap();

  const { containerRef, handleRef, leftPanelRef } = useResizablePanels({
    initialLeftPanelWidth: "50%",
    minPanelWidth: 450,
  });

  useEffect(() => {
    if (!planningMap) {
      // planningMap is probably closed, or just not loaded, so this is a no-op
      return;
    } else {
      planningMap.resize();
    }
  }, [isToPlanShown, planningMap]);

  const selectedDate = new Date(useSelectedDate());

  // Resize the map when the left panel is resized with debounce
  useEffect(() => {
    if (!leftPanelRef.current || !planningMap) {
      return;
    }

    let resizeTimeoutId: NodeJS.Timeout | null = null;

    const resizeHandler = () => {
      if (resizeTimeoutId) {
        clearTimeout(resizeTimeoutId);
      }

      resizeTimeoutId = setTimeout(() => {
        planningMap.resize();
        resizeTimeoutId = null; // Clear timeout ID after execution
      }, 200);
    };

    const observer = new ResizeObserver(resizeHandler);
    observer.observe(leftPanelRef.current);

    return () => {
      if (resizeTimeoutId) {
        clearTimeout(resizeTimeoutId);
      }
      observer.disconnect();
    };
  }, [leftPanelRef, planningMap]);

  const {
    data: unplannedOccurrencesAndGroups,
    isPending,
    isError,
  } = useUnplannedOccurrencesAndGroups(
    selectedDate.toDateString(),
    selectedDate.toDateString(),
  );

  const numberOfUnplannedOccurrences =
    unplannedOccurrencesAndGroups?.length ?? 0;

  return (
    <section className={styles.container}>
      <PageToolbar showDaySwitcher>
        <div className={styles.pageOptions}>
          <StandaloneCheckbox
            label={
              isError || isPending
                ? t`Att planera`
                : t`Att planera (${numberOfUnplannedOccurrences})`
            }
            name="to-plan-view"
            checked={isToPlanShown}
            onChange={(e) => setIsToPlanShown(e.target.checked)}
          />
          <StandaloneCheckbox
            label={t`Expanderat läge`}
            name="expanded-view"
            checked={isExpanded}
            onChange={(e) => setIsExpanded(e.target.checked)}
          />
          <StandaloneCheckbox
            label={t`Visa karta`}
            name="show-map"
            checked={isMapShown}
            onChange={(e) => setIsMapShown(e.target.checked)}
          />
        </div>
      </PageToolbar>

      <div className={styles.contentWrapper}>
        {isToPlanShown ? (
          <section className={styles.toPlan}>
            <ShiftsSideBar isPendingAddToShift={isPendingAddToShift} />
          </section>
        ) : null}
        <div
          ref={containerRef}
          className={clsx(
            styles.shiftsAndMap,
            isToPlanShown ? "" : styles.sideBarHidden,
            isMapShown ? "" : styles.mapHidden,
          )}
        >
          <div ref={leftPanelRef} className={styles.shifts}>
            <ShiftsSection
              isExpanded={isExpanded}
              onPendingAddToShift={setIsPendingAddToShift}
            />
          </div>
          <div
            ref={handleRef}
            role="separator"
            aria-orientation="vertical"
            className={styles.handle}
            title={t`Dra för att justera bredd`}
          ></div>
          {isMapShown ? (
            <div className={styles.map}>
              <ShiftsMap />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};
