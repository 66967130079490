import { msg } from "@lingui/core/macro";
import type { MessageDescriptor } from "@lingui/core";
import z from "zod";

const recurringOptions = [
  "never",
  "everyDay",
  "every2ndDay",
  "every3rdDay",
  "every5thDay",
  "every8thWeek",
  "custom",
] as const;
export const recurringOptionSchema = z.enum(recurringOptions);
export type IRecurringOption = z.infer<typeof recurringOptionSchema>;

export const recurringDictionary: Record<IRecurringOption, MessageDescriptor> =
  {
    never: msg`Aldrig`,
    everyDay: msg`Varje dag`,
    every2ndDay: msg`Varannan dag`,
    every3rdDay: msg`Var tredje dag`,
    every5thDay: msg`Var femte dag`,
    every8thWeek: msg`Var åttonde vecka`,
    custom: msg`Anpassad`,
  };

export const recurringNumberDictionary: Record<number, IRecurringOption> = {
  0: recurringOptionSchema.Values.never,
  1: recurringOptionSchema.Values.everyDay,
  2: recurringOptionSchema.Values.every2ndDay,
  3: recurringOptionSchema.Values.every3rdDay,
  5: recurringOptionSchema.Values.every5thDay,
  56: recurringOptionSchema.Values.every8thWeek,
};

export const recurrenceToNumberDictionary: Record<
  IRecurringOption,
  0 | 1 | 2 | 3 | 5 | 56
> = {
  [recurringOptionSchema.Values.never]: 0,
  [recurringOptionSchema.Values.everyDay]: 1,
  [recurringOptionSchema.Values.every2ndDay]: 2,
  [recurringOptionSchema.Values.every3rdDay]: 3,
  [recurringOptionSchema.Values.every5thDay]: 5,
  [recurringOptionSchema.Values.every8thWeek]: 56,
  // Edge case, doesn't really make sense.
  [recurringOptionSchema.Values.custom]: 0,
};

const frequencyOptions = ["oneTime", "recurring"] as const;
export const activityFrequencySchema = z.enum(frequencyOptions);
export type IActivityFrequencySchema = z.infer<typeof activityFrequencySchema>;

export const activityFrequencyDictionary: Record<
  IActivityFrequencySchema,
  MessageDescriptor
> = {
  oneTime: msg`Ett tillfälle`,
  recurring: msg`Återkommande`,
};
