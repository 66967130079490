import clsx from "clsx";
import type { HTMLInputTypeAttribute } from "react";
import { forwardRef, useId } from "react";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import styles from "./InputField.module.scss";
import { t } from "@lingui/core/macro";

interface IInputField {
  autoComplete?: string;
  errorMessage?: string;
  formatHint?: string;
  icon?: React.ReactNode;
  name: string;
  label: React.ReactNode;
  showOptionalLabel: boolean;
  description?: React.ReactNode;
  suggestions?: string[];
  type?: HTMLInputTypeAttribute;
  width?: "full" | "fit";
  size?: "default" | "compact";
}

const InputField = forwardRef<HTMLInputElement | null, IInputField>(
  (
    {
      autoComplete,
      errorMessage,
      formatHint,
      name,
      label,
      showOptionalLabel,
      description,
      icon,
      suggestions,
      type,
      width = ["date", "number", "time"].includes(type ?? "") ? "fit" : "full",
      size = "default",
      ...rest
    },
    ref,
  ) => {
    const id = useId();
    const datalistId = useId();
    const descriptionId = useId();

    const displayLabel =
      `${label} ${showOptionalLabel ? t`(valfri)` : ""}`.trim();

    return (
      <div className={clsx(styles.inputFieldWrapper, styles[width])}>
        {icon ? (
          <div className={styles.labelWithIcon}>
            {icon}
            <label className={styles.label} htmlFor={id}>
              {displayLabel}
            </label>
          </div>
        ) : (
          <label className={styles.label} htmlFor={id}>
            {displayLabel}
          </label>
        )}
        {description ? (
          <p id={descriptionId} className={styles.description}>
            {description}
          </p>
        ) : undefined}
        {errorMessage ? (
          <div className={styles.errorMessage}>
            <ErrorMessage message={errorMessage} weight="bold" />
          </div>
        ) : undefined}
        <input
          aria-invalid={Boolean(errorMessage) || undefined}
          aria-describedby={
            description !== undefined ? descriptionId : undefined
          }
          autoComplete={autoComplete || "off"}
          className={clsx(styles.input, styles[`size-${size}`])}
          formNoValidate={true}
          id={id}
          list={suggestions ? datalistId : undefined}
          name={name}
          placeholder={formatHint}
          ref={ref}
          type={type}
          {...rest}
        />
        {suggestions ? (
          <datalist id={datalistId}>
            {suggestions.map((suggestion) => (
              <option key={suggestion} value={suggestion} />
            ))}
          </datalist>
        ) : undefined}
      </div>
    );
  },
);
InputField.displayName = "InputField";

export default InputField;
