import { logisticsApi } from "@/api/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { employee, employees } from "@models/shifts";
import { useUserProfile } from "./Users";

export const fetchEmployee = async (employeeId: string) => {
  const employeeResponse = await logisticsApi.get(`/employees/${employeeId}`);
  const parsedEmployee = employee.parse(employeeResponse.data);
  return parsedEmployee;
};

async function fetchEmployees() {
  const employeesResponse = await logisticsApi.get("/employees");
  const parsedEmployees = employees.parse(employeesResponse.data);
  return parsedEmployees;
}

export const employeeKeys = {
  all: ["employees"] as const,
  lists: () => [...employeeKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...employeeKeys.lists(), { filters }] as const,
  detail: (id: string) => [...employeeKeys.all, id, "details"] as const,
};

export const useCurrentEmployee = () => {
  const { employeeNumber } = useUserProfile();
  return useQuery({
    queryKey: employeeKeys.detail(employeeNumber),
    queryFn: () => fetchEmployee(employeeNumber),
  });
};

export const useEmployee = (employeeNumber: string) => {
  return useQuery({
    queryKey: employeeKeys.detail(employeeNumber),
    queryFn: () => fetchEmployee(employeeNumber),
  });
};

export const useEmployees = () => {
  return useQuery({
    queryKey: employeeKeys.lists(),
    queryFn: fetchEmployees,
  });
};
