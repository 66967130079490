import { msg } from "@lingui/core/macro";
import type { IAddActivityFormData } from "@/forms/AddActivityForm/AddActivityForm";
import {
  activityFrequencySchema,
  recurringOptionSchema,
} from "@/forms/AddActivityForm/Scheduling/recurrenceUtils";
import { categorySchema, timeOfDaySchema } from "@models/activities";
import { medicalCompetenceSchema } from "@models/shifts";
import type { MessageDescriptor } from "@lingui/core";
import {
  allPatientTypes,
  type IPatientType,
  patientTypeSchema,
} from "@models/patients";
import type { IInvoicingCode } from "@/api/Invoicing";

export type IBaseTemplate = Pick<
  IAddActivityFormData,
  | "category"
  | "doubleStaffing"
  | "duration"
  | "recurrence"
  | "frequency"
  | "recurrencesPerDay"
  | "requiredCompetences"
  | "timeCategory"
  | "timeSensitivity"
  | "timeslots"
  | "hidden"
  | "weekdays"
  | "measurements"
> & {
  templateOptions: {
    showInList: boolean;
    patientTypes: IPatientType[];
  };
  templateId: string;
  templateRevision: number;
  title: MessageDescriptor;
  description: MessageDescriptor | null;
};

type IAnyTimeOfDayTemplate = Omit<
  IBaseTemplate,
  "timeslots" | "timeSensitivity" | "recurrencesPerDay"
>;

type IInvoicingCodeTemplate = IBaseTemplate & {
  invoicingCodes: IInvoicingCode[];
};

// Must be a subset of IAddActivityFormData
export type ITemplate =
  | IBaseTemplate
  | IAnyTimeOfDayTemplate
  | IInvoicingCodeTemplate;

export type ITemplateKey =
  | "returnKey"
  | "keyQuittance"
  | "checkCatheter"
  | "riskAssessment"
  | "patientVitals"
  | "connectMedicationPump"
  | "medicationPumpReplacement"
  | "medicationPumpCheck"
  | "ownMeasurementBloodSugar"
  | "intravenousMedication"
  | "handoverMedicationList"
  | "securityReceipt"
  | "teamRound"
  | "medicationReviewSimple"
  | "medicationReviewAdvanced"
  | "bloodTransfusion"
  | "retrievalLoanedIVStand"
  | "medication"
  | "admissionInterview"
  | "workEnvironmentRiskAssessment"
  | "leaveInformationMap"
  | "updatePatientInformation"
  | "updateInTakeCare"
  | "documentRiskAssessment"
  | "removeMedicationPump";

export const templates: Record<ITemplateKey, ITemplate> = {
  bloodTransfusion: {
    title: msg`Blodtransfusion`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    recurrence: recurringOptionSchema.Values.never,
    frequency: activityFrequencySchema.Values.oneTime,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 30,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "adcaa5ea-be14-412d-15dd-295d9c9bc0d3",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "e48d1185-cb43-4a6e-8c24-589baaa19759",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Transfusion, erytrocyter, allogen",
        code: "ZV400",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  medicationPumpReplacement: {
    title: msg`Byte av pump`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Kom ihåg att ändra tiden till 24 timmar efter att pumpen kopplats och ta sedan bort "dold för patienten"`,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "00:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "4aa85636-438a-4c1f-e6db-ff0439795d7f",
    templateRevision: 1,
  },
  patientVitals: {
    title: msg`Egen mätning`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "5c378477-6ad8-427c-4ce2-a4330326dbb7",
    templateRevision: 1,
  },
  ownMeasurementBloodSugar: {
    title: msg`Egen mätning blodsocker`,
    category: categorySchema.Values.PatientMeasurementTask,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeSensitivity: "0.5",
    requiredCompetences: [],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: ["bloodGlucose"],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "08a98552-a695-418c-0b72-4a16eebd8971",
    templateRevision: 1,
  },
  checkCatheter: {
    title: msg`Kontroll medicinska accesser och dränage`,
    category: categorySchema.Values.HomeVisit,
    description: msg`Centrala infarter, kateter, stomi, såromläggning, drän, nefrostomi`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "018f0e9f-8a9b-4def-1fa8-b4e68c4abf4e",
    templateRevision: 1,
  },
  connectMedicationPump: {
    title: msg`Koppla pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }], // The user must choose a time
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "03df1a61-e695-4a06-5204-6495296e81a0",
    templateRevision: 1,
  },
  removeMedicationPump: {
    title: msg`Koppla bort pump`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "--:--" }],
    timeSensitivity: "2",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "e810f5f6-49bf-4c57-751e-4e36fa989b1a",
    templateRevision: 1,
  },
  intravenousMedication: {
    title: msg`Läkemedel intravenöst`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "4447a1e4-2ead-4544-1a34-4329a5f1d035",
    templateRevision: 1,
  },
  medicationReviewSimple: {
    title: msg`Läkemedelsgenomgång, enkel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "dbf69b69-314f-4522-9605-2da378ccde55",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "64cfeac5-ff19-4fdf-b97d-57bcaf737a8f",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Läkemedelsgenomgång, enkel",
        code: "XV015",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  medicationReviewAdvanced: {
    title: msg`Läkemedelsgenomgång, fördjupad`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "7a77c1b9-3194-4c2b-9452-e6289b35c3c5",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "10d44b1e-2198-4585-b099-c245aad0e884",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Läkemedelsgenomgång, fördjupad",
        code: "XV016",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  keyQuittance: {
    title: msg`Nyckelkvittens`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "713cd2e5-8986-4d3f-be3f-05f1f148a4f6",
    templateRevision: 1,
  },
  medicationPumpCheck: {
    title: msg`Pumpkontroll digital`,
    category: categorySchema.Values.VideoCall,
    description: null,
    frequency: activityFrequencySchema.Values.recurring,
    recurrence: recurringOptionSchema.Values.everyDay,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "18:00" }],
    timeSensitivity: "1",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: true,
      patientTypes: allPatientTypes,
    },
    templateId: "c024914b-68b6-46f2-b7d4-96b0efff5b57",
    templateRevision: 1,
  },
  riskAssessment: {
    title: msg`Riskbedömningar`,
    category: categorySchema.Values.HomeVisit,
    description: msg`ROAG, MNA, Downton och Norton`,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "d00182c9-2f23-4c81-2d31-d73f60d3f2c6",
    templateRevision: 1,
  },
  teamRound: {
    title: msg`Teamkonferens`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.every8thWeek,
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [
      medicalCompetenceSchema.Values.NursePractitioner,
      medicalCompetenceSchema.Values.MedicalDoctor,
      medicalCompetenceSchema.Values.Dietitian,
      medicalCompetenceSchema.Values.Physiotherapist,
      medicalCompetenceSchema.Values.MedicalSocialWorker,
      medicalCompetenceSchema.Values.OccupationalTherapist,
    ],
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "6e082062-5fa7-419c-043a-4df9b7b7d8c5",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "41351101-db8a-4966-8fd5-3503eda99ee6",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Konferens om patient",
        code: "XS008",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  returnKey: {
    title: msg`Återlämning nyckel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    timeCategory: timeOfDaySchema.Values.Specific,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "31fd878f-de34-490b-1418-6e087a7f61ed",
    templateRevision: 1,
  },
  handoverMedicationList: {
    title: msg`Lämna aktuell läkemedelslista`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "fd2e4b2e-f4f4-479c-c758-6c784870cf13",
    templateRevision: 1,
  },
  securityReceipt: {
    title: msg`Trygghetskvitto`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.MedicalDoctor],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "eee65ed3-d248-424c-924e-62bf06f359c7",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "01b05847-4f2a-4584-b7c4-09df69f2d1e7",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Trygghetskvitto",
        code: "UV017",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  retrievalLoanedIVStand: {
    title: msg`Hämtning av utlånad droppställning`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  medication: {
    title: msg`Läkemedel`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    recurrencesPerDay: "1",
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  // TODO: check if competences are correct
  admissionInterview: {
    title: msg`Inskrivningssamtal`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: false,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
    invoicingCodes: [
      {
        id: "8fd8fd35-46a3-4334-95ee-db17a4474aa3",
        // eslint-disable-next-line lingui/no-unlocalized-strings
        name: "Konferens med patient",
        code: "XS007",
        type: "procedureCode",
        patientType: "AdvancedHomeCare",
      },
    ],
  },
  workEnvironmentRiskAssessment: {
    title: msg`Arbetsmiljö riskbedömning`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  leaveInformationMap: {
    title: msg`Lämna informationsmapp`,
    category: categorySchema.Values.HomeVisit,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Specific,
    timeslots: [{ time: "09:00" }],
    timeSensitivity: "4",
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  updatePatientInformation: {
    title: msg`Uppdatera telefonnr patient och anhörig`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  updateInTakeCare: {
    title: msg`Uppdatera ASIH-telefonnr i TakeCare`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: [patientTypeSchema.Values.AdvancedHomeCare],
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
  documentRiskAssessment: {
    title: msg`Dokumentera riskbedömningar i Take Care`,
    category: categorySchema.Values.AdminTask,
    description: null,
    frequency: activityFrequencySchema.Values.oneTime,
    recurrence: recurringOptionSchema.Values.never,
    timeCategory: timeOfDaySchema.Values.Any,
    requiredCompetences: [medicalCompetenceSchema.Values.NursePractitioner],
    doubleStaffing: false,
    duration: 15,
    hidden: true,
    measurements: [],
    weekdays: [],
    templateOptions: {
      showInList: false,
      patientTypes: allPatientTypes,
    },
    templateId: "c4c6c5f2-4f9e-4e7b-0b6a-4e8c7f8b8c5d",
    templateRevision: 1,
  },
};
